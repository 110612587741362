import { LoadingOutlined } from "@ant-design/icons";
import { memo } from "react";
import Tooltip from "./Tooltip";

const ToolbarButton = ({ title, type, keys, icon, loader, disable, onClick }) => {
  return loader ? (
    <div className="flex justify-center items-center h-18 w-18 bg-grey-200 rounded-sm">
      <LoadingOutlined className="text-inputOrange" />
    </div>
  ) : (
    <Tooltip key={keys} direction="top" title={title}>
      <i
        key={keys}
        className={`${icon} flex items-center justify-center rounded-sm h-18 w-18 ${
          disable
            ? "bg-grey-300 text-gray-600 cursor-not-allowed"
            : `${
                type === "delete" ? "bg-red-500 text-red-500 hover:bg-red-500" : "bg-inputOrange text-inputOrange hover:bg-inputOrange"
              } bg-opacity-10  hover:text-white  cursor-pointer`
        }`}
        role="presentation"
        {...(!disable && {
          onClick,
        })}
      />
    </Tooltip>
  );
};

export default memo(ToolbarButton);
