import React, { useState, useContext, useEffect } from "react";
import { Progress, Modal } from "antd";
import Button from "../../Widget/Button";
import { Context } from "../../Context";
import "../../index.css";

const ConnectionTestModule = () => {
  const contextValues = useContext(Context);
  const [steps, setSteps] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (contextValues?.connectionSteps?.length > 0) {
      setSteps(contextValues?.connectionSteps);
      setOpen(true);
    }
  }, [contextValues?.connectionSteps]);
  
  return (
    <Modal
      title="Connection Status"
      open={open}
      centered
      onCancel={() => setOpen(false)}
      footer={null}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div className="modal-content-scroll">
        {steps?.length > 0 &&
          steps?.map((step, index) => (
            <div key={index} className="pt-[10px] pb-[15px]">
              <h3>{step.name}</h3>
              <Progress
                percent={step.passed ? 100 : 0}
                status={step.passed ? "success" : "exception"}
                showInfo={true}
              />
              {step.passed === false && step.message && (
                <div className="error-message">{step.message}</div>
              )}
            </div>
          ))}
      </div>
      <div className="flex justify-end">
        <Button onClick={() => setOpen(false)} className="bg-inputOrange text-white">Ok</Button>
      </div>
    </Modal>
  );
};

export default ConnectionTestModule;
