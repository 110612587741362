import React, { useState, useEffect, useContext } from "react";
import constants from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../src/assets/Logo.png";
import Context from "../../Context";
import InputV2 from "../../Widget/InputV2";
import CustomTooltip from "../../Widget/Tooltip";
import { Popover } from "antd";
import LogoSmall from "../../assets/sideLogo.png";
import { getLocalVariables, setLocalVariables } from "../../utils/utils";

const Sidebar = () => {
  const location = useLocation();
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [activeMenu, setActiveMenu] = useState({});
  const [userProfile, setUserProfile] = useState(false);
  const userData = getLocalVariables("USER_DATA");

  useEffect(() => {
    setActiveMenu({ ...activeMenu, active: location?.pathname });
  }, [location]);

  const Menus = [
    {
      id: 1,
      title: "Dashboard",
      icon: "fa-solid fa-tv",
      url: constants.route.Dashboard,
    },

    ...(userData?.role?.type == "admin" ||
    userData?.role?.type == "authenticated" ||
    userData?.role?.type == "read_only"
      ? [
          {
            id: 2,
            title: "Data Sources",
            icon: "fa-solid fa-warehouse",
            url: constants.route.Catalog,
          },
          {
            id: 3,
            title: "Data Dictionary",
            icon: "fa-solid fa-tachograph-digital",
            url: constants.route.DataDictionary,
          },
          {
            id: 4,
            title: "Classifications",
            icon: "fa-solid fa-tags",
            url: constants.route.Classification,
          },
          {
            id: 5,
            title: "Security Policies ",
            icon: "fa-solid fa-laptop-code",
            url: constants.route.PoliciesTable,
          },
          {
            id: 6,
            title: "User Management",
            icon: "fa-solid fa-users",
            url: constants.route.Group,
          },
        ]
      : []),
    {
      id: 7,
      title: "Settings",
      icon: "fa fa-cog",
      url: "/settings",
    },
  ];

  const content = (
    <div
      className="flex flex-col w-fit space-y-3"
      style={{ minWidth: "10rem" }}
    >
      {/* <ul className="p-3">
        <li
          className="space-x-6 h-24 w-full flex items-center px-6 font-semibold hover:text-white outline-1 hover:bg-inputOrange rounded-md cursor-pointer"
          role="presentation"
          onClick={() => {
            // navigate(constants.route.myProfile);
          }}
        >
          <i className="fa-regular fa-bell" /> <span>Notification</span>
        </li>
      </ul> */}
      <div className="p-3">
        <div
          className="space-x-6 h-24 w-full flex items-center px-6 font-semibold hover:text-white outline-1 hover:bg-inputOrange rounded-md cursor-pointer"
          role="presentation"
          onClick={async() => {
            const [azurecloud_flag, superAdminExist] = await Promise.all([
              contextValues.getCloudAccountSettings(),
              contextValues.checkSuperAdminExist({runonce: true}),
            ]);
               if(azurecloud_flag?.data || superAdminExist){
                localStorage.clear();
                setLocalVariables("AZURE_FLAG",azurecloud_flag?.data?.length > 0 ? "available" : null);
                setLocalVariables("SUPER_ADMIN_EXIST",superAdminExist);
                navigate(constants.route.Login);
              }
            }}
        >
          <i className="fa-solid fa-arrow-right-from-bracket" />{" "}
          <span>Sign Out</span>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`flex flex-col shadow-lg bg-white ${
        contextValues?.isSidebarOpen
          ? "w-160 min-w-160 scale-out"
          : "min-w-42 scale-in"
      } h-screen justify-between relative p-2 transition-all ease-in-out duration-600`}
    >
      <div className="w-full h-full bg-backgroundColor border rounded-md">
        <div
          role="presentation"
          onClick={() => {
            navigate(constants.route.Dashboard);
            setActiveMenu({ active: constants.route.Dashboard });
          }}
          className={`flex w-full items-center ${
            contextValues?.isSidebarOpen ? "justify-start" : "justify-center"
          } cursor-pointer border-393945 border-solid px-6 h-41 border-b border-gray-300`}
        >
          <div className={`flex w-full items-center ${
            contextValues?.isSidebarOpen ? "justify-start mr-14 ml-14 " : "justify-center"
          } cursor-pointer`}>
          <img
            key={contextValues?.isSidebarOpen ? "black_logo" : "white_logo"}
            className={`${
              contextValues?.isSidebarOpen ? "w-100" : "w-17 mr-2"
            } transition-none h-fit`}
            src={contextValues?.isSidebarOpen ? Logo : LogoSmall}
            alt="Guardiuum Logo"
            />
            </div>
        </div>

        <div
          className={`transition-all ease-in-out duration-500 transform origin-top mt-4  border-0
           overflow-y-auto`}
        >
          <ul className="flex flex-col justify-between mt-8 space-y-6">
            {Menus.filter((item) => {
              return !search
                ? item
                : item?.title?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    (item?.actions &&
                      item?.actions?.some((action) =>
                        action?.label
                          ?.toLowerCase()
                          ?.includes(search?.toLowerCase())
                      ));
            }).map((Menu, index) => {
              if (Menu.id !== undefined) {
                return (
                  <React.Fragment key={index}>
                    <li
                      to={Menu.url}
                      className={`${
                        (
                          Menu?.url != constants.route.Dashboard
                            ? activeMenu?.active?.includes(Menu.url)
                            : activeMenu?.active == Menu.url
                        )
                          ? "bg-inputOrange rounded-sm"
                          : "text-white"
                      } flex group items-center m-auto py-3 ${
                        contextValues?.isSidebarOpen
                          ? "px-12 justify-between"
                          : "justify-center"
                      } cursor-pointer text-sm w-11/12`}
                      role="presentation"
                      onClick={() => {
                        setActiveMenu({
                          active: Menu.url,
                          ...(Menu.collapsable && {
                            [Menu?.id]: !activeMenu?.[Menu?.id],
                          }),
                        });
                        if (!Menu.collapsable) {
                          navigate(Menu.url);
                        }
                      }}
                    >
                      <div className="flex items-center">
                        <CustomTooltip
                          direction="right"
                          title={!contextValues?.isSidebarOpen && Menu.title}
                        >
                          <i
                            className={`${
                              Menu?.icon
                            } flex items-center justify-center text-xs leading-4 h-16 w-16 rounded-sm  ${
                              contextValues?.isSidebarOpen && "mr-5"
                            } ${
                              (
                                Menu?.url != constants.route.Dashboard
                                  ? activeMenu?.active?.includes(Menu.url)
                                  : activeMenu?.active == Menu.url
                              )
                                ? "text-white"
                                : "text-7F8194 group-hover:text-black"
                            }`}
                          />
                        </CustomTooltip>
                        {contextValues?.isSidebarOpen && (
                          <div
                            className={`whitespace-nowrap leading-4 text-xs font-medium ${
                              (
                                Menu?.url != constants.route.Dashboard
                                  ? activeMenu?.active?.includes(Menu.url)
                                  : activeMenu?.active == Menu.url
                              )
                                ? "text-white"
                                : "text-1a3248"
                            }`}
                          >
                            {Menu.title}
                          </div>
                        )}
                      </div>
                      {Menu.actions && contextValues?.isSidebarOpen && (
                        <i
                          className={`fa-solid fa-chevron-right  ${
                            activeMenu[Menu.id] && "transform -rotate-90"
                          } ${
                            (
                              Menu?.url != constants.route.Dashboard
                                ? activeMenu?.active?.includes(Menu.url)
                                : activeMenu?.active == Menu.url
                            )
                              ? "text-white"
                              : "text-black"
                          } text-[10px] duration-500 transition-transform `}
                        />
                      )}
                    </li>
                    <ul
                      className={`my-4 space-y-2 transition-all ease-in-out duration-500 transform origin-top overflow-hidden ${
                        (Menu?.url != constants.route.Dashboard
                          ? activeMenu?.active?.includes(Menu.url)
                          : activeMenu?.active == Menu.url) &&
                        activeMenu?.[Menu?.id]
                          ? "h-full max-h-96 scale-in"
                          : "h-0 max-h-0 scale-out"
                      }`}
                    >
                      {Menu?.actions?.map((action, i) => (
                        <li
                          key={i}
                          role="presentation"
                          onClick={() => {
                            setActiveMenu({
                              active: Menu.url,
                              [Menu?.id]: true,
                            });
                            navigate(action.link);
                          }}
                          className={`flex group items-center m-auto py-2 px-5 cursor-pointer font-medium text-xs w-9/12 ${
                            (location.search !== ""
                              ? location.search
                              : location.pathname
                            ).includes(action.link)
                              ? "rounded-sm text-inputOrange"
                              : "text-gray-500 hover:text-gray-800"
                          }`}
                        >
                          {contextValues?.isSidebarOpen ? (
                            <>
                              <i className="fa-solid fa-circle text-[4px] ml-6" />
                              <span className="whitespace-nowrap ml-8 leading-4 truncate text-[13px]">
                                {action.label}
                              </span>
                            </>
                          ) : (
                            <CustomTooltip
                              direction="right"
                              title={
                                !contextValues?.isSidebarOpen && action.label
                              }
                            >
                              <i
                                className={`${action?.icon} flex items-center justify-center text-xs leading-4 h-16 w-16 rounded-sm`}
                              />
                            </CustomTooltip>
                          )}
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </ul>
        </div>
      </div>
      {contextValues?.isSidebarOpen && (
        <div
          className={`space-x-6 text-xs transition-all ease-in-out duration-500 transform origin-top overflow-hidden ${
            userProfile ? "h-52 scale-in" : "h-0 scale-out"
          }`}
        >
          {/* <div
            className="flex items-center m-auto px-12 w-11/12 space-x-6 text-xs h-24 py-3 mt-6 hover:bg-inputOrange hover:text-white text-1a3248 font-medium rounded-md border cursor-pointer scale-in"
            role="presentation"
            onClick={() => {
              // localStorage.clear();
              // navigate(constants.route.Login);
            }}
          >
            <i className="fa-solid fa-bell" />{" "}
            <span>Notification</span>
          </div> */}
          <div
            className="flex items-center m-auto px-12 w-11/12 space-x-6 text-xs h-24 py-3 mt-6 hover:bg-inputOrange hover:text-white text-1a3248 font-medium rounded-md border cursor-pointer scale-in"
            role="presentation"
            onClick={async() => {
            const [azurecloud_flag, superAdminExist] = await Promise.all([
              contextValues.getCloudAccountSettings(),
              contextValues.checkSuperAdminExist({runonce: true}),
            ]);
               if(azurecloud_flag?.data || superAdminExist){
                localStorage.clear();
                setLocalVariables("AZURE_FLAG",azurecloud_flag?.data?.length > 0 ? "available" : null);
                setLocalVariables("SUPER_ADMIN_EXIST",superAdminExist);
                navigate(constants.route.Login);
              }
            }}
          >
            <i className="fa-solid fa-arrow-right-from-bracket" />{" "}
            <span>Sign Out</span>
          </div>
        </div>
      )}
      <div
        className={`flex w-full gap-6 mt-4 mb-4 px-6 item-center cursor-pointer ${
          !contextValues?.isSidebarOpen && "justify-center"
        }`}
        role="presentation"
        onClick={() => setUserProfile(!userProfile)}
      >
        {!contextValues?.isSidebarOpen ? (
          <Popover placement="rightBottom" content={content} arrow>
            <i className="fa-solid fa-user text-sm cursor-pointer text-black flex items-center justify-center rounded-full bg-gray-200 min-h-26 min-w-26" />
          </Popover>
        ) : (
          <i className="fa-solid fa-user text-sm cursor-pointer text-black flex items-center justify-center rounded-full bg-gray-200 min-h-26 min-w-26" />
        )}
        {contextValues?.isSidebarOpen && (
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-col w-96">
              <div className=" text-xs font-semibold truncate">
                {userData?.username?.split(/[@.]/)[0]}
              </div>
              <div className="text-[10px] truncate">{userData?.email}</div>
            </div>
            <i
              className={`fa-solid fa-chevron-up text-[10px] w-12 h-12 border rounded-full text-4B5675 font-bold hover:bg-inputOrange hover:text-white cursor-pointer flex justify-center items-center ${
                userProfile && "transform -rotate-180"
              }`}
            />
          </div>
        )}
      </div>

      <i
        className={`fa-solid fa-chevron-right ${
          contextValues?.isSidebarOpen && "transform rotate-180"
        } cursor-pointer absolute -right-5 top-16 ${
          contextValues?.loadingCounter !== 0 && !contextValues?.isPopupOpen
            ? "z-990"
            : "z-100"
        } ${
          contextValues?.openSidebar ? "z-100 opacity-0" : "z-100"
        }   rounded-full border text-2xs h-14 w-14 flex items-center justify-center bg-white duration-500 transition-transform  `}
        role="presentation"
        onClick={() => {
          // setLocalVariables("SIDEBAR_ACTION", !contextValues?.isSidebarOpen);
          contextValues?.setIsSidebarOpen(!contextValues?.isSidebarOpen);
        }}
      />
    </div>
  );
};

export default Sidebar;
