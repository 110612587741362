import React from "react";
import { Modal } from "antd";
import InputV2 from "../../Widget/InputV2";
import Commonfield from "../../Widget/Commonfield";

function AddEditModal({
  openModal,
  setOpenModal,
  formData,
  handleSubmit,
  handleCommonfieldChange,
}) {
  return (
    <Modal
      open={openModal?.open}
      centered
      onCancel={() => setOpenModal({ open: false })}
      className="settingsModal hover:bg-#007eff"
      maskClosable={false}
      title={openModal?.title}
      okText={"SUBMIT"}
      okButtonProps={{
        disabled: !formData?.name || !formData?.description,
        style: {
          backgroundColor:
            !formData?.name || !formData?.description ? "#ccc" : "#007eff",
          color: "#fff",
        },
      }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={handleSubmit}
    >
      <div className="w-full px-8 mt-10">
        <div className="flex items-center">
          <span className="mr-1 text-xs">Name</span>
          <span className="text-red-500">*</span>
        </div>
        <InputV2
          placeholder="Name"
          name="name"
          value={formData?.name || ""}
          onChange={handleCommonfieldChange}
          error="field"
        />
      </div>
      <div className="w-full px-8 mt-10">
        <div className="flex items-center">
          <span className="mr-1 text-xs">Display Name</span>
        </div>
        <InputV2
          placeholder="Display Name"
          name="displayName"
          value={formData?.displayName || ""}
          onChange={handleCommonfieldChange}
        />
      </div>
      <div className="w-full px-8 mt-10">
        <div className="flex items-center">
          <span className="mr-1 text-xs">Description</span>
          <span className="text-red-500">*</span>
        </div>
        <Commonfield
          className="mb-8"
          rows="4"
          name="description"
          onChange={handleCommonfieldChange}
          value={formData?.description || ""}
          textarea
        />
      </div>
    </Modal>
  );
}

export default AddEditModal;
