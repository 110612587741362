import { useLocation } from "react-router";
import { memo, useContext } from "react";
import { Context } from "../Context";
import { getLocalVariables } from "../utils/utils";
import constants from "../Constants";

const Loading = () => {
  const location = useLocation();
  const contextValues = useContext(Context);
  const sidebarAction = JSON.parse(getLocalVariables("SIDEBAR_ACTION")) ?? true;
  if (
    contextValues?.loadingCounter !== 0 ||
    (location.pathname?.includes("charts") || location.pathname?.includes("sql-lab") ? contextValues?.executeLoading : false)
  )
    return (
      <div
        className={`flex items-center justify-center h-screen ${
          location?.pathname?.includes(constants.route.Login) || !getLocalVariables("ADMIN_TOKEN")
            ? `w-full`
            : sidebarAction
            ? `w-[calc(100%-16rem)]`
            : `w-[calc(100%-4.2rem)]`
        } absolute top-0 bg-white bg-opacity-5 backdrop-blur-sm z-9999`}
      >
        <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-r-4 border-inputOrange" />
      </div>
    );
};

export default memo(Loading);
