import { useEffect, useContext, memo } from "react";
import { toast } from "react-toastify";
import { Form, Modal, Button,Input } from "antd";
import { Context } from "../../Context";

const DeletePopup = ({ open, setOpen, onDelete, message, onDismiss, children, name, defaultValue, onSave, updateWorkspaceData }) => {
  const contextValues = useContext(Context);
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);

  useEffect(() => {
    contextValues.setIsPopupOpen(open);
  }, [open]);

  useEffect(() => {
    if (onSave && defaultValue) {
      const parts = defaultValue?.split("/");
      if (parts && parts.length > 0) {
        form.setFieldsValue({ confirm: parts[parts.length - 1] });
      }
    }
  }, [open]);

  const handleCancel = () => {
    setOpen({ open: false });
    form.resetFields();
  };

  const isDisabled = formValues?.confirm !== "" && formValues?.confirm === "delete";
  const renameFieldDisable = formValues?.confirm !== "";

  return (
    <>
      {children}
      <Modal className="delete-modal" open={open} centered footer={null} onCancel={handleCancel}>
        <Form layout="vertical" autoComplete="off" form={form}>
          <div className="flex justify-start mb-4 mr-24">
            {!onDismiss && <span className="text-red-500 text-sm mr-2 font-[simsun]">*</span>}
            <span className={onDismiss && "text-lg"}>{name ?? <>Enter "<strong>delete</strong>" to confirm deletion</>}</span>
          </div>
          {onDismiss ? (
            <div>{message}</div>
          ) : (
            <Form.Item
            name="confirm"
            rules={[
              {
                required: !onDismiss,
                message: `Field is required`,
              },
            ]}
            >
              <Input className="w-full" />
            </Form.Item>
          )}
          <div className="flex items-center mt-10 justify-end">
            {!onDismiss ? (
              <>
                <Button
                  className="mr-8 uppercase"
                  onClick={() => {
                    setOpen({ open: false });
                    form.resetFields();
                  }}
                  htmlType="button"
                >
                  Cancel
                </Button>
                {onDelete && (
                  <Button
                    className="uppercase"
                    type="primary"
                    disabled={!isDisabled}
                    onClick={() => {
                      onDelete();
                      form.resetFields();
                    }}
                  >
                    Delete
                  </Button>
                )}
                {onSave && (
                  <Button
                    className="uppercase"
                    type="primary"
                    disabled={!renameFieldDisable}
                    onClick={async () => {
                      const parts = defaultValue?.split("/");
                      if (parts && parts.length > 0) {
                        parts[parts.length - 1] = formValues?.confirm;
                        const newPath = parts.join("/");
                        const res = await contextValues?.patchRenameContents({ old_path: defaultValue, data: { path: newPath } });
                        if ([200, 201].includes(res?.status) && res?.data) {
                          await updateWorkspaceData({ path: defaultValue, updatePath: newPath, isRename: true });
                          form.resetFields();
                        }
                        onSave();
                        if (res?.type) {
                          toast.success(`${res?.type == "directory" ? "Folder" : "File"} name has been successfully renamed`, {
                            position: toast.POSITION.TOP_CENTER,
                          });
                        }
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </>
            ) : (
              <Button className="ml-8 uppercase" type="primary" onClick={onDismiss}>
                Dismiss
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default memo(DeletePopup);
