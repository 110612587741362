import React from 'react';
import { message, ConfigProvider } from 'antd';

const showMessage = (msg, type = 'default') => {
  switch (type) {
    case 'success':
      message.success(msg);
      break;
    case 'error':
      message.error(msg);
      break;
    case 'info':
      message.info(msg);
      break;
    case 'warn':
      message.warning(msg);
      break;
    default:
      message.info(msg);
  }
};

const MessageContainer = () => {
  return null;
};

export const showToast = (msg, type = 'default', position = 'top') => {
  ConfigProvider.config({
    prefixCls: 'custom-message',
    getPopupContainer: () => document.body,
    duration: 3,
    top: position === 'top' ? 24 : undefined,
    bottom: position === 'bottom' ? 24 : undefined,
  });

  showMessage(msg, type);
};

const Toastify = ({ position }) => {
  return (
    <div>
      <MessageContainer position={position} />
    </div>
  );
};

export default Toastify;
