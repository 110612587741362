import { memo, useState } from "react";
import {getLocalVariables,capitalizeFirstLetterOfEachWord} from "../utils/utils";
import Breadcrumb from "./Breadcrumb";
import Button from "./Button";
import ToolbarButton from "./ToolbarButton";
import { Input, Dropdown } from 'antd';

const HeaderV2 = ({
  title,
  isEditing,
  setIsEditing,
  disabled,
  validationError,
  setValidationError,
  jupyterFileData,
  breadCrumb,
  search,
  setSearch,
  searchPlaceholder,
  status,
  onUndoClick,
  canUndo,
  canRedo,
  onRedoClick,
  onPreview,
  onReload,
  buttonIcon,
  nopadding,
  buttonText,
  onButtonClick,
  isEditable,
  showStatics,
  setShowStatics,
  updateWorkspaceData,
  buttons,
  service_buttons,
  bg,
  className,
}) => {
  const userData = getLocalVariables("USER_DATA");
  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <div className="w-full min-h-44 sticky top-0 z-99">
      <div
        className={`w-full h-44 flex items-center justify-between ${
          bg && bg ? `bg-${bg}` : "bg-backgroundColor"
        } ${nopadding ?? "px-10"}`}
      >
        <div className="flex flex-col justify-center items-start space-y-2">
          {!breadCrumb && (
            <span className={`font-medium text-15 leading-none ${className}`}>
            {title ? title : ["authenticated", "admin"].includes(userData?.role?.type) ? `Welcome back, ${capitalizeFirstLetterOfEachWord(userData?.username)}` : 'Welcome back'}
          </span>
          )}
          {breadCrumb && (
            <Breadcrumb
              items={breadCrumb}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              validationError={validationError}
              setValidationError={setValidationError}
              jupyterFileData={jupyterFileData}
              isEditable={isEditable}
              updateWorkspaceData={updateWorkspaceData}
            />
          )}
        </div>
        {searchPlaceholder && (
          <>
            <div className={`flex space-x-8 text-sm items-center ${className ?? ""}`}>
                <div className="flex items-center space-x-10 w-250 bg-white rounded-md border overflow-hidden px-8 h-26">
                  <input
                    className="w-full h-20 text-xs"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={searchPlaceholder}
                  />
                  <i
                    className="fa-solid fa-xmark cursor-pointer"
                    role="presentation"
                    onClick={() => {
                      setSearchOpen(false);
                      if (search) {
                        setSearch("");
                      } else {
                        setSearch(null);
                      }
                    }}
                  />
                </div>
          
            </div>
          </>
        )}

        <div>
          {buttons && (
            <div className="flex items-center gap-6">
              <div>
                <ToolbarButton
                  icon="fa-solid fa-arrow-left"
                  onClick={() =>
                    setShowStatics({
                      ...showStatics,
                      showStaticTogle: false,
                    })
                  }
                  title={"Close"}
                />
              </div>
              <div className="flex items-center gap-4">
                {buttons?.map((item) => (
                  <div className="flex items-center gap-4">
                    {item.label === "Edit Connection" && (
                      <ToolbarButton
                        icon="fa-solid fa-edit"
                        disable={item?.disabled}
                        onClick={item?.onClick}
                        title={item?.label}
                      />
                    )}
                    {item.label === "Test Connection" && (
                      <ToolbarButton
                        icon="fa-solid fa-plug"
                        disable={item?.disabled}
                        onClick={item?.onClick}
                        title={item?.label}
                      />
                    )}
                    {item.label === "Submit" && (
                      <ToolbarButton
                        icon="fa-solid fa-save"
                        disable={item?.disabled}
                        onClick={item?.onClick}
                        title={item?.label}
                      />
                    )}
                    {item.label === "Add Metadata Ingestion" && (
                      <ToolbarButton
                        icon="fa-solid fa-network-wired"
                        disable={item?.disabled}
                        onClick={item?.onClick}
                        title={item?.label}
                      />
                    )}
                    {item.label === "Add Profiler Ingestion" && (
                      <ToolbarButton
                        icon="fa-solid fa-tags"
                        disable={item?.disabled}
                        onClick={item?.onClick}
                        title={item?.label}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {service_buttons && (
          <div className="flex items-center gap-6">
            {service_buttons?.map((item) => (
              <Button onClick={item.onClick}>{item?.label}</Button>
            ))}
          </div>
        )}
        {buttonText && (
          <div className="flex items-center">
            <Button
              startIcon={buttonIcon}
              onClick={onButtonClick}
              disable={disabled}
            >
              {buttonText}
            </Button>
          </div>
        )}
        {/* <i className="fa-regular fa-bell cursor-pointer" /> */}
      </div>
    </div>
  );
};

export default memo(HeaderV2);
