import { memo } from "react";

const Button = ({
  children,
  variant,
  startIcon,
  endIcon,
  color,
  className,
  submit,
  showLoading,
  disable,
  borderColor,
  size,
  textStyle,
  ...rest
}) => {
  return (
    <button
      type={submit ? "submit" : "button"}
      disabled={disable}
      {...rest}
      className={`flex truncate w-fit justify-center items-center ${
        size === "small" ? "px-10 h-16 text-2xs" : "px-16 h-20 text-[15px]"
      } rounded-sm ${className} ${
        variant === "contained"
          ? `${
              disable
                ? "bg-greyScale_200 text-xs"
                : `bg-inputOrange hover:bg-inputOrange  text-white text-xs font-semibold`
            } ${disable && "cursor-not-allowed"}`
          : variant === "outline"
          ? `${
              disable
                ? "bg-grey-300 text-xs text-gray-600 cursor-not-allowed"
                : "border-0.8 text-inputOrange border-inputOrange bg-white font-semibold"
            }`
          : `${
              disable
                ? "bg-grey-300 text-xs text-gray-600 cursor-not-allowed"
                : "text-white text-xs bg-inputOrange hover:bg-opacity-70"
            } font-semibold text-${color}`
      } 
     
    
    `}
    >
      {disable && showLoading ? (
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-r-1 border-loaderColor mr-4" />
      ) : (
        startIcon
      )}
      <span className={`${textStyle}` ?? "uppercase"}>{children}</span>
      {endIcon}
    </button>
  );
};

export default Button;
