import NoData from "./Nodata";
import Pagination from "./Pagination";
import Button from "./Button";

const Table = ({
  tableHeader,
  activeTab,
  children,
  tableHeight,
  style,
  title,
  removeSidePadding,
  rowsPerPage,
  page,
  setPage,
  setPrevPage,
  currentPage,
  className,
  setCurrentPage,
  totalPost,
  onBackButton,
  createButtonText,
  onButtonClick,
  showBackIcon,
  startIcon,
  onBack,
  headerChild,
  validation = false,
  dropDownButton,
  svg,
  additionalTxt,
  ...rest
}) => {
    return (
    <div className={`${className} w-full`} {...rest}>
      <div className="w-full rounded-sm overflow-x-auto">
        <div className={` bg-white overflow-x-auto p-10 rounded-1`}>
          {(dropDownButton || title || showBackIcon || createButtonText) && (
            <div className="flex justify-between items-center pb-12 px-14">
              <div className="flex w-full items-center">
                {showBackIcon && (
                  <i
                    className="fa-solid fa-angle-left font-semibold flex-row mr-5 cursor-pointer hover:text-blue-600 h-20 w-20 flex items-center justify-center hover:bg-d4eef4 rounded-full"
                    role="presentation"
                    onClick={onBack}
                  />
                )}
                <div
                  className={`${
                    title && "mr-8"
                  } text-lightBlack text-[20px] font-700 w-full`}
                >
                  {title}
                </div>
                {headerChild}
              </div>
              <div className="flex items-center">
                {dropDownButton && dropDownButton}
                {createButtonText && (
                  <Button
                    variant="outline"
                    startIcon={
                      startIcon ?? <i className="fa-solid fa-plus mr-6" />
                    }
                    onClick={onButtonClick}
                    disable={validation}
                  >
                    {createButtonText}
                  </Button>
                )}
              </div>
            </div>
          )}
          <div
            className={`${
              tableHeight || (createButtonText || dropDownButton ? "max-h-[calc(100vh-15.2rem)]" : "max-h-[calc(100vh-11.9rem)]")
            } overflow-auto`}
            style={style}
          >
          <table className="w-full">
            <thead className="w-full h-36 sticky top-0 z-[9] text-headingColor bg-headingBg text-xs rounded">
              <tr className="rounded">
                {tableHeader.map((headerItem, index) => {
                  if (
                    !activeTab ||
                    (activeTab === "access" &&
                      headerItem.label !== "Masking" &&
                      headerItem.label !== "Row Level Filter")
                  ) {
                    return (
                      <th
                        className={`px-32 py-8 text-${
                          headerItem.position
                        } headingColor ${index === 0 && "rounded-l-lg"} ${
                          tableHeader.length - 1 === index && "rounded-r-lg"
                        }`}
                        key={index}
                        style={{
                          paddingLeft: "1.2rem",
                          paddingRight: "1.2rem",
                          textAlign: "left",
                        }}
                      >
                        {headerItem.label}
                      </th>
                    );
                  } else if (
                    activeTab === "masking" &&
                    headerItem.label !== "Row Level Filter" &&
                    headerItem.label !== "Tags Filter"
                  ) {
                    return (
                      <th
                        className={`px-32 py-8 text-${
                          headerItem.position
                        } headingColor ${index === 0 && "rounded-l-lg"} ${
                          tableHeader.length - 1 === index && "rounded-r-lg"
                        }`}
                        key={index}
                        style={{
                          paddingLeft: "1.2rem",
                          paddingRight: "1.2rem",
                          textAlign: "left",
                        }}
                      >
                        {headerItem.label}
                      </th>
                    );
                  } else if (
                    activeTab === "rowFilter" &&
                    headerItem.label !== "Masking" &&
                    headerItem.label !== "Columns" &&
                    headerItem.label !== "Tags Filter"
                  ) {
                    return (
                      <th
                        className={`px-32 py-8 text-${
                          headerItem.position
                        } headingColor ${index === 0 && "rounded-l-lg"} ${
                          tableHeader.length - 1 === index && "rounded-r-lg"
                        }`}
                        key={index}
                        style={{
                          paddingLeft: "1.2rem",
                          paddingRight: "1.2rem",
                          textAlign: "left",
                        }}
                      >
                        {headerItem.label}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>
            <tbody>
              {children?.length === 0 || children == undefined ? (
                <tr>
                  <td
                    colSpan={tableHeader?.length}
                    className="text-center py-4"
                  >
                    {svg ? (
                      <div className="flex flex-col items-center justify-center">
                        <img src={svg} />
                        <p className="text-xs">{additionalTxt}</p>
                      </div>
                    ) : (
                      <NoData />
                    )}
                  </td>
                </tr>
              ) : (
                children
              )}
            </tbody>
          </table>
        </div>
        </div>
      </div>

      {rowsPerPage && (
        <Pagination
          className="px-12"
          rowsPerPage={rowsPerPage}
          totalPost={totalPost}
          setPage={setPage}
          page={page}
          // setPrevPage={setPrevPage}
        />
      )}
    </div>
  );
};

export default Table;
