import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../Context";
import constants from "../../Constants";
import Button from "../../Widget/Button";

const ClassificationSidebar = ({
  className,
  open,
  setOpenModal,
  rest,
  setPage,
  sideMenus,
  setFormData,
  activeMenu,
  setActiveMenu,
}) => {
  const contextValues = useContext(Context);
  return (
    <div className={`flex ${className}`} {...rest}>
      <div
        className={`flex flex-col ${
          open ? "w-170 min-w-170 scale-out" : "min-w-0 w-0 scale-in"
        } border-r-2 h-[calc(100vh-4.4rem)] overflow-hidden overflow-y-auto bg-white transition-all ease-in-out duration-600`}
      >
        <div
          className={`transition-all ease-in-out duration-500 transform origin-top mt-4  border-0 overflow-y-auto`}
        >
          <Button
            variant="outline"
            startIcon={<i className="fa-solid fa-plus mr-6" />}
            onClick={() => {
              setFormData(null);
              setOpenModal({ open: true, title: "Adding New Classification",type: "classifications" });
            }}
            className={"!w-[93%] ml-[10px] mt-[10px]"}
          >
            Add Classification
          </Button>
          <ul className="flex flex-col justify-between mt-8 space-y-6">
            {sideMenus?.map((menu) => (
              <li
                key={menu.id}
                className={`${
                  (
                    menu?.url != constants.route.Dashboard
                      ? activeMenu?.active == menu?.menuName
                      : activeMenu?.active == menu.menuName
                  )
                    ? "bg-[#e6f5ff] rounded-sm border-2 border-l-blue-500"
                    : "text-[#0975e7]"
                } flex group items-center m-auto py-3 ${
                  contextValues?.isSidebarOpen
                    ? "px-12 justify-between"
                    : "px-12 justify-between"
                } cursor-pointer text-sm w-11/12`}
                role="presentation"
                onClick={() => {
                  setPage(1)
                  setActiveMenu({
                    active: menu.menuName,
                    ...(menu.collapsable && {
                      [menu?.id]: !activeMenu?.[menu?.id],
                    }),
                  });
                }}
              >
                <div className="flex items-center">
                  <div
                    className={`whitespace-nowrap leading-4 text-xs font-medium ${
                      (
                        menu?.url != constants.route.Dashboard
                          ? activeMenu?.active == menu?.menuName
                          : activeMenu?.active == menu.menuName
                      )
                        ? "text-[#0975e7]"
                        : "text-1a3248"
                    }`}
                  >
                    {menu.title}
                  </div>
                  {menu?.disabled && <span className="text-[10px] ml-4 font-medium border-solid border-1 border-grey-400 px-3 rounded-md">{menu?.disabled ? "Disabled" : ""}</span>}
                </div>
                <div
                  className={`whitespace-nowrap leading-4 text-xs font-medium rounded-sm border-1 px-6 
                         ${
                           (
                             menu?.url != constants.route.Dashboard
                               ? activeMenu?.active == menu?.menuName
                               : activeMenu?.active == menu.menuName
                           )
                             ? "border-blue-400 bg-inputOrange text-white"
                             : "border-sky-400"
                         }`}
                >
                  {menu?.count}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ClassificationSidebar;
