import { Dropdown } from "antd";
import { memo } from "react";

const CustomMenu = ({ items, children,className, ...rest }) => {
  return (
    <div className={`${!children && className ? className : "w-full"} flex justify-center items-center`}>
      <Dropdown menu={{ items } ?? {}} trigger={["click"]} {...rest}>
        {children ?? (
          <i
            className="fa-solid fa-ellipsis-vertical text-sm text-lightGrey h-16 w-16 flex items-center justify-center hover:text-blue-500 hover:bg-blue-50 hover:bg-opacity-30 rounded-full cursor-pointer"
            role="presentation"
            onClick={(e) => e.preventDefault()}
          />
        )}
      </Dropdown>
    </div>
  );
};

export default memo(CustomMenu);
