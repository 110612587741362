// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #007eff;
    --primary-hover-color: #007efe;
    --black-color: #000000;
    --white-color: #ffffff;
  }`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,8BAA8B;IAC9B,sBAAsB;IACtB,sBAAsB;EACxB","sourcesContent":[":root {\n    --primary-color: #007eff;\n    --primary-hover-color: #007efe;\n    --black-color: #000000;\n    --white-color: #ffffff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
