import { memo } from "react";
import CustomTooltip from "./Tooltip";

const CustomCard = ({ children, tooltip, className }) => {
  return (
    <>
      {tooltip ? (
        <CustomTooltip direction="top" title={tooltip}>
          <div className={`rounded-8 border bg-white ${className}`}>{children}</div>
        </CustomTooltip>
      ) : (
        <div className={`rounded-8 bg-white ${className}`}>{children}</div>
      )}
    </>
  );
};

export default memo(CustomCard);
