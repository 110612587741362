import React, { useState } from "react";
import Table from "../../Widget/Table";
import constants from "../../Constants";
import { filterDataBySearch, formatTexts } from "../../utils/utils";
import { Tooltip } from "antd";
import IngestionSvg from "../../assets/Ingestion.svg";

function ClassificationTable({
  data,
  search,
  page,
  setPage,
  rowsPerPage,
  activeMenu,
  onEdit,
  onDelete
}) {
  const[moreOptions, setMoreOptions] = useState({});

  const handleToggleOptions = (index) => {
    setMoreOptions((prevState) => ({
      ...prevState,
      [index]: { show: !prevState?.[index]?.show },
    }));
  };

  return (
    <Table
      tableHeight="max-h-[calc(100vh-7.6rem)]"
      setPage={setPage}
      page={page}
      rowsPerPage={rowsPerPage}
      tableHeader={constants.classificationHeader}
      totalPost={data?.length}
      svg={IngestionSvg}
      additionalTxt={"No tags have been defined in this category. To create a new tag, simply click on the 'Add Tag' button."}
    >
      {filterDataBySearch(data, search)
      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
      .map((item, index) => (
        <tr
          key={index + 1}
          className="border-b w-full hover:bg-tableHoverColor last:border-0"
        >
          <td className="px-16 py-5 font-bold cursor-pointer justify-center text-xs">
            {item.name ?? "-"}
          </td>
          <td className="px-16 py-5 cursor-pointer justify-center text-xs">
            {item.displayName ? item?.displayName : " -- -- "}
          </td>
          <td className="px-16 py-5 cursor-pointer justify-center text-xs">
          {formatTexts(item?.description, moreOptions[index], index)}
           {item?.description && activeMenu?.active == "Tier" && <div
              className="group flex items-center space-x-4 cursor-pointer"
              role="presentation"
              onClick={() => handleToggleOptions(index)} // Update the click event
            >
              <i
                className={`fa-solid fa-angle-down ${
                  (moreOptions?.[index]?.show) && "transform -rotate-180"
                } group-hover:text-inputOrange duration-500 transition-transform`}
              />
              <span className="group-hover:text-inputOrange">
                {moreOptions?.[index]?.show ? "show less" : "show more"}
              </span>
            </div>}
            <span className="text-grey">Usage: </span>
            {item.usageCount ? (
              <span className="text-blue font-bold">{item.usageCount}</span>
            ) : (
              <span className="text-grey">Not Used</span>
            )}
          </td>
          <td className="px-16 py-18 cursor-pointer justify-start flex items-start text-xs">
            <span
              className="fa-solid fa-pencil w-16 hover:text-black"
              onClick={() => onEdit(item)}
            ></span>
            <Tooltip
              title={ item?.provider === "system" ? "Deletion is disabled for system generated tags": ""}
              placement="left"
            >
              <span
                className={`fa-solid fa-trash-can w-16 ${
                  item?.provider === "system"
                    ? "text-gray-400 cursor-not-allowed"
                    : "hover:text-red cursor-pointer"
                }`}
                onClick={ item?.provider !== "system" ? () => onDelete(item?.id, "tags") : null}
              ></span>
            </Tooltip>
          </td>
        </tr>
      ))}
    </Table>
  );
}

export default ClassificationTable;
