import React, { memo, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import Commonfiled from "./Commonfield";
import { Context } from "../Context";
import constants from "../Constants";
import CustomTooltip from "./Tooltip";
import { getFileType, truncateString } from "../utils/utils";

const Breadcrumb = ({
  items,
  className,
  isEditing,
  setIsEditing,
  validationError,
  setValidationError,
  jupyterFileData,
  updateWorkspaceData,
  isEditable = false,
}) => {
  const [editedFileName, setEditedFileName] = useState(null);
  const [errorField, setErrorField] = useState();
  const contextValues = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const validateInput = (input) => {
    if (jupyterFileData?.type === "directory") {
      if (input.trim() === "") {
        setErrorField("field");
        return "Folder name cannot be empty.";
      }
    } else {
      const trimmedInput = input.trim();
      if (trimmedInput === "") {
        setErrorField("file");
        return "File name cannot be empty.";
      } else if (input.includes(" ")) {
        if (/^\w+\s/.test(input)) {
          setErrorField("file");
          return "File name cannot contain spaces.";
        } else {
          return "File name cannot contain spaces.";
        }
      } else if (!/^[a-zA-Z0-9_\-.\d]*[123]*$/.test(trimmedInput)) {
        setErrorField("file");
        return "File name can only contain letters, underscores (_), hyphens (-), periods (.), and the digits 1, 2, 3.";
      }
    }
    return null;
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEditedFileName(inputValue);
    const error = validateInput(inputValue);
    setValidationError(error);
  };

  if (items?.length > 0)
    return (
      <div className={`${className} w-full h-12 flex items-center space-x-4`}>
        {items?.map((path, index) => (
          <React.Fragment key={index}>
            <div
              className={`${
                index !== items?.length - 1 &&
                "hover:text-inputOrange cursor-pointer h-full flex items-center"
              }`}
              role="presentation"
              onClick={items?.length - 1 !== index ? path?.onClick : () => {}}
            >
              <span className="flex items-center justify-center font-medium text-md">
                {path.icon && <i className={`${path.icon}`} />}
                {/* {path.title && <span>{path.title}</span>} */}
                {index !== items?.length - 1 ? (
                  <span className="leading-tight">
                    {truncateString(path.title, 15)}
                  </span>
                ) : (
                  path.title && (
                    <>
                      {isEditing ? (
                        <Tooltip
                          title={validationError}
                          color="red"
                          open={validationError}
                        >
                          <Commonfiled
                            variant="standard"
                            className={`w-96 ${
                              validationError && "text-red-500"
                            }`}
                            size="small"
                            type="text"
                            value={editedFileName}
                            onChange={handleInputChange}
                            error={errorField}
                          />
                        </Tooltip>
                      ) : (
                        <span className="leading-tight">
                          {truncateString(path.title, 30)}
                        </span>
                      )}
                      {!validationError && isEditable && (
                        <>
                          {isEditing ? (
                            <div className="flex items-center space-x-6 ml-6">
                              <CustomTooltip direction="top" title="Save">
                                <i
                                  className="fa-solid fa-check cursor-pointer"
                                  role="presentation"
                                  onClick={async () => {
                                    const parts = queryParams
                                      .get("path")
                                      ?.split("/");
                                    if (parts && parts.length > 0) {
                                      parts[parts.length - 1] = editedFileName;
                                      const newPath = parts.join("/");
                                      await contextValues?.patchRenameContents({
                                        old_path: queryParams.get("path"),
                                        data: { path: newPath },
                                      });
                                      await updateWorkspaceData({
                                        path: queryParams?.get("path"),
                                        updatePath: newPath,
                                        isRename: true,
                                      });
                                      if (
                                        location.pathname.includes("/notebook")
                                      ) {
                                        navigate(
                                          constants.route.notebook +
                                            `?path=${newPath}&view=${getFileType(
                                              editedFileName
                                            )}`
                                        );
                                      } else {
                                        navigate(
                                          `${constants.route.workspace}?path=${newPath}`
                                        );
                                      }
                                      setEditedFileName(null);
                                      setIsEditing(false);
                                    }
                                  }}
                                />
                              </CustomTooltip>
                              <CustomTooltip direction="top" title="Cancel">
                                <i
                                  className="fa-solid fa-xmark cursor-pointer"
                                  role="presentation"
                                  onClick={() => {
                                    setIsEditing(false);
                                  }}
                                />
                              </CustomTooltip>
                            </div>
                          ) : (
                            <CustomTooltip direction="top" title="Edit">
                              <i
                                className="fa-solid fa-pencil fa-2xs ml-4 cursor-pointer mb-4"
                                role="presentation"
                                onClick={() => {
                                  setEditedFileName(path.title);
                                  setIsEditing(true);
                                }}
                              />
                            </CustomTooltip>
                          )}
                        </>
                      )}
                    </>
                  )
                )}
              </span>
            </div>
            {items?.length - 1 !== index && (
              <i className="fa-solid fa-angle-right text-md flex justify-center items-center" />
            )}
          </React.Fragment>
        ))}
      </div>
    );
};

export default memo(Breadcrumb);
