const META_URL = process.env.REACT_APP_META_HOST_URL;
const STRAPI_URL = process.env.REACT_APP_STRAPI_HOST_URL;

const constants = {

  route: {
    default: "/",
    DataService: "/data-service",
    PoliciesTable: "/policies",
    Service: `/data-catalog/service`,
    Group: `/group`,
    DataDictionary: `/data-dictionary`,
    Classification: `/classification`,
    DataQuality: `/data-quality`,
    Catalog: `/data-catalog`,
    Dashboard: `/dashboard`,
    Login: `/login`,
    ForgotPassword: `/forgot-password`,
    Signup: `/signup`,
    EmailVerification: `/verification`,
    settings: `/settings`,
    security: "/security",
    UserVerification: `/confirm`,
    pageNotFound: "/404",
    configuration: `/configuration`
  },

  url: {
    addDBService: `${META_URL}/v1/services/databaseServices`,
    messagingService: `${META_URL}/v1/services/messagingServices`,
    policies: `${STRAPI_URL}/policies`,
    registration: `${STRAPI_URL}/auth/local/register`,
    checkSuperAdminExist: `${STRAPI_URL}/check-superAdmin-exists`,
    resendEmail: `${STRAPI_URL}/auth/send-email-confirmation`,
    login: `${STRAPI_URL}/auth/local`,
    getOpenMetadataToken: `${STRAPI_URL}/getOpenMetadataToken`,
    userMe: `${STRAPI_URL}/users/me`,
    updateActiveLink: `${STRAPI_URL}/user/me`,
    Connectors: `${STRAPI_URL}/db-connectors`,
    DBConnDetails: `${STRAPI_URL}/db-connection-details`,
    dashboards: `${META_URL}/v1/search/query?q=&index=all&from=0&size=10000&include=non-deleted&query_filter={"query":{"bool":{}}}&track_total_hits=true&fetch_source=true`,
    history: `${STRAPI_URL}/histories`,
    users: `${STRAPI_URL}/users`,
    register: `${STRAPI_URL}/register`,
    updatePassword: `${STRAPI_URL}/update-password`,
    getlineage: `${META_URL}/getlineage`,
    groups: `${STRAPI_URL}/groups`,
    getCatalogs: `${META_URL}/v1/databases`,
    getCustomNameSpace: `${META_URL}/v1/databaseSchemas`,
    getTables: `${META_URL}/v1/tables`,
    getColumns: `${META_URL}/v1/tables/name`,
    testConnections: `${META_URL}/v1/automations/workflows`,
    testConnectionTrigger: `${META_URL}/v1/automations/workflows/trigger`,
    ingestionPipelines: `${META_URL}/v1/services/ingestionPipelines`,
    ingestionPipelinesDeploy: `${META_URL}/v1/services/ingestionPipelines/deploy`,
    ingestionPipelinesTrigger: `${META_URL}/v1/services/ingestionPipelines/trigger`,
    TestcaseUrl: `${META_URL}/v1/dataQuality/testCases`,
    getDataQuality: `${META_URL}/v1/dataQuality/testSuites`,
    LineageUrl: `${META_URL}/v1/lineage/table/name`,
    SettingDb: `${STRAPI_URL}/settings`,
    auth_with_microsoft: `${STRAPI_URL}/auth/microsoft/callback`,
    login_with_microsoft: `${STRAPI_URL}/connect/microsoft`,
    user_permission: `${STRAPI_URL}/user-premissions/permissions`,
    cloudAccountSettings: `${STRAPI_URL}/cloud-account-settings`,
    topics: `${META_URL}/v1/topics`,
    topics_name: `${META_URL}/v1/topics/name`,
    getColumnTags: `${STRAPI_URL}/column-tag-getTagClassification`,
    createColumnTag: `${STRAPI_URL}/column-tag-createTag`,
    tagClassification: `${META_URL}/v1/classifications`,
    createClassification: `${STRAPI_URL}/column-tag-createTagClassification`,
    DQExecutable: `${META_URL}/v1/dataQuality/testSuites/executable`,
    userConnections: `${STRAPI_URL}/userconnections`,
    Classifications: `${META_URL}/v1/classifications`,
    tags: `${META_URL}/v1/tags`,

    //3rd party api's
    googleScope: `https://www.googleapis.com/auth/drive.readonly`,
    googleToken: `https://oauth2.googleapis.com/token`
  },

  components: {
    user_role: [
      { value: "admin", label: "Admin" },
      { value: "authenticated", label: "Authenticated" },
      { value: "read_only", label: "Read only" },
      { value: "data_access_role", label: "Data Access Role" },
    ],
  },

  IngestionHead: [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Type", position: "left" },
    { id: 3, label: "Schedule", position: "left" },
    { id: 4, label: "Recent Runs", position: "left" },
    { id: 4, label: "Actions", position: "left" },
  ],

  columnsHead: [
    { id: 1, label: "Id", position: "left" },
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Data Type", position: "left" },
    { id: 4, label: "Constraint", position: "left" },
    { id: 5, label: "Data Length", position: "left" },
    { id: 6, label: "Tags", position: "left" },
    { id: 7, label: "", position: "left" },
  ],

  catalogOvrwTabData:  [
    { title: "Column Profile", value: "column profile" },
    { title: "Lineage", value: "lineage" },
    { title: "Data Quality", value: "quality" },
  ],

  TestHead: [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Column", position: "left" },
    { id: 3, label: "Last Run", position: "left" },
    { id: 4, label: "INCIDENT", position: "left" },
    { id: 5, label: "Actions", position: "left" },
  ],

  By_Table_Header: [
    { id: 1, label: "Name", position: "left" },
    { id: 2, label: "Tests", position: "left" },
    { id: 3, label: "Success %", position: "left" }
  ],

   dataDictionaryMenus : [
    {
      id: 1,
      title: "Tables",
      icon: "fa-solid fa-table-cells",
      url: `/data-dictionary`,
      menuName: "table",
      count: 0
    },
    {
      id: 2,
      title: "Databases",
      icon: "fa fa-database",
      url: `/data-dictionary`,
      menuName: "database",
      count: 0
    },
    {
      id: 3,
      title: "Database Schemas",
      icon: "fa fa-code-branch",
      url: `/data-dictionary`,
      menuName: "database_schema",
      count: 0
    },
    {
      id: 4,
      title: "Stored Procedures",
      icon: "fa fa-server",
      url: `/data-dictionary`,
      menuName: "stored_procedure",
      count: 0
    },
    {
      id: 5,
      title: "Tags",
      icon: "fa fa-tags",
      url: `/data-dictionary`,
      menuName: "tag",
      count: 0
    },
  ],

  cardDataList: [
    { id: 1, title: "Total Tests", percent: 0, showProgress: false },
    { id: 2, title: "Success", percent: 0, className: "custom_success" },
    { id: 3, title: "Aborted", percent: 0, className: "custom_progress" },
    { id: 4, title: "Failed", percent: 0, className: "custom_fail" },
  ],

  fieldHeadSchemas: [
    { id: 1, label: "Id", position: "left" },
    { id: 1, label: "Schema", position: "left" },
    { id: 2, label: "Updated At", position: "left" }
  ],

  fieldHeadTables: [
    { id: 1, label: "Id", position: "left" },
    { id: 1, label: "Tables", position: "left" },
    { id: 2, label: "Updated At", position: "left" }
  ],

  DQTableTestcase: [
    {
      "label": "Table Column Count To Be Between",
      "value": "tableColumnCountToBeBetween",
      "parameterDefination": [
        {
          "name": "minColValue",
          "displayName": "Min"
        },
        {
          "name": "maxColValue",
          "displayName": "Max"
        }
      ]
    },
    {
      "label": "Table Column Count To Equal",
      "value": "tableColumnCountToEqual",
      "parameterDefination": [
        {
          "name": "columnCount",
          "displayName": "Count"
        }
      ]
    },
    {
      "label": "Table Column Name To Exist",
      "value": "tableColumnNameToExist",
      "parameterDefination": [
        {
          "name": "columnName",
          "displayName": "Column Name"
        }
      ]
    },
    {
      "label": "Table Column Names To Match Set",
      "value": "tableColumnToMatchSet",
      "parameterDefination": [
        {
          "name": "columnNames",
          "displayName": "Column Names"
        },
        {
          "name": "ordered",
          "displayName": "Ordered"
        }
      ]
    },
    {
      "label": "Custom SQL Query",
      "value": "tableCustomSQLQuery",
      "parameterDefination": [
        {
          "name": "sqlExpression",
          "displayName": "SQL Expression"
        },
        {
          "name": "strategy",
          "displayName": "Strategy"
        },
        {
          "name": "threshold",
          "displayName": "Threshold"
        }
      ]
    },
    {
      "label": "Table Row Count To Be Between",
      "value": "tableRowCountToBeBetween",
      "parameterDefination": [
        {
          "name": "minValue",
          "displayName": "Min"
        },
        {
          "name": "maxValue",
          "displayName": "Max"
        }
      ]
    },
    {
      "label": "Table Row Count To Equal",
      "value": "tableRowCountToEqual",
      "parameterDefination": [
        {
          "name": "value",
          "displayName": "Count"
        }
      ]
    },
    {
      "label": "Table Row Inserted Count To be Between",
      "value": "tableRowInsertedCountToBeBetween",
      "parameterDefination": [
        {
          "name": "min",
          "displayName": "Min Row Count"
        },
        {
          "name": "max",
          "displayName": "Max Row Count"
        },
        {
          "name": "columnName",
          "displayName": "Column Name"
        },
        {
          "name": "rangeType",
          "displayName": "Range Type"
        },
        {
          "name": "rangeInterval",
          "displayName": "Interval"
        }
      ]
    }
  ],

  DQColumnTestcase: [
    {
        "label": "Column Value Lengths To Be Between",
        "value": "columnValueLengthsToBeBetween",
        "parameterDefination": [
            {
                "name": "minLength",
                "displayName": "Min"
            },
            {
                "name": "maxLength",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Value Max. To Be Between",
        "value": "columnValueMaxToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForMaxInCol",
                "displayName": "Min"
            },
            {
                "name": "maxValueForMaxInCol",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Value Mean To Be Between",
        "value": "columnValueMeanToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForMeanInCol",
                "displayName": "Min"
            },
            {
                "name": "maxValueForMeanInCol",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Value Median To Be Between",
        "value": "columnValueMedianToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForMedianInCol",
                "displayName": "Min"
            },
            {
                "name": "maxValueForMedianInCol",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Value Min. To Be Between",
        "value": "columnValueMinToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForMinInCol",
                "displayName": "Min"
            },
            {
                "name": "maxValueForMinInCol",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Values Missing Count",
        "value": "columnValuesMissingCount",
        "parameterDefination": [
            {
                "name": "missingCountValue",
                "displayName": "Missing Count"
            },
            {
                "name": "missingValueMatch",
                "displayName": "Missing Value to Match"
            }
        ]
    },
    {
        "label": "Column Values Sum To Be Between",
        "value": "columnValuesSumToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForColSum",
                "displayName": "Min"
            },
            {
                "name": "maxValueForColSum",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Value Std Dev To Be Between",
        "value": "columnValueStdDevToBeBetween",
        "parameterDefination": [
            {
                "name": "minValueForStdDevInCol",
                "displayName": "Min"
            },
            {
                "name": "maxValueForStdDevInCol",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Values To Be Between",
        "value": "columnValuesToBeBetween",
        "parameterDefination": [
            {
                "name": "minValue",
                "displayName": "Min"
            },
            {
                "name": "maxValue",
                "displayName": "Max"
            }
        ]
    },
    {
        "label": "Column Values To Be In Set",
        "value": "columnValuesToBeInSet",
        "parameterDefination": [
            {
                "name": "allowedValues",
                "displayName": "Allowed Values"
            }
        ]
    },
    {
        "label": "Column Values To Be Not In Set",
        "value": "columnValuesToBeNotInSet",
        "parameterDefination": [
            {
                "name": "forbiddenValues",
                "displayName": "Forbidden Values"
            }
        ]
    },
    {
        "label": "Column Values To Be Not Null",
        "value": "columnValuesToBeNotNull",
        "parameterDefination": []
    },
    {
        "label": "Column Values To Be Unique",
        "value": "columnValuesToBeUnique",
        "parameterDefination": []
    },
    {
        "label": "Column Values To Match Regex Pattern",
        "value": "columnValuesToMatchRegex",
        "parameterDefination": [
            {
                "name": "regex",
                "displayName": "RegEx Pattern"
            }
        ]
    },
    {
        "label": "Column Values To Not Match Regex",
        "value": "columnValuesToNotMatchRegex",
        "parameterDefination": [
            {
                "name": "forbiddenRegex",
                "displayName": "RegEx Pattern"
            }
        ]
    }
  ],

  DQPayloadParams: [
    { key: 'minLength', path: '/parameterValues/0/value' },
    { key: 'maxLength', path: '/parameterValues/1/value' },
    { key: 'minColValue', path: '/parameterValues/0/value' },
    { key: 'maxColValue', path: '/parameterValues/1/value' },
    { key: 'columnCount', path: '/parameterValues/0/value' },
    { key: 'columnName', path: '/parameterValues/0/value' },
    { key: 'minValue', path: '/parameterValues/0/value' },
    { key: 'maxValue', path: '/parameterValues/1/value' },
    { key: 'value', path: '/parameterValues/0/value' },
    { key: 'min', path: '/parameterValues/0/value' },
    { key: 'max', path: '/parameterValues/1/value' },
    { key: 'minValueForMaxInCol', path: '/parameterValues/0/value' },
    { key: 'maxValueForMaxInCol', path: '/parameterValues/1/value' },
    { key: 'minValueForMeanInCol', path: '/parameterValues/0/value' },
    { key: 'maxValueForMeanInCol', path: '/parameterValues/1/value' },
    { key: 'minValueForMedianInCol', path: '/parameterValues/0/value' },
    { key: 'maxValueForMedianInCol', path: '/parameterValues/1/value' },
    { key: 'minValueForMinInCol', path: '/parameterValues/0/value' },
    { key: 'maxValueForMinInCol', path: '/parameterValues/1/value' },
    { key: 'missingCountValue', path: '/parameterValues/0/value' },
    { key: 'missingValueMatch', path: '/parameterValues/1/value' },
    { key: 'minValueForColSum', path: '/parameterValues/0/value' },
    { key: 'maxValueForColSum', path: '/parameterValues/1/value' },
    { key: 'minValueForStdDevInCol', path: '/parameterValues/0/value' },
    { key: 'maxValueForStdDevInCol', path: '/parameterValues/1/value' },
    { key: 'allowedValues', path: '/parameterValues/0/value' },
    { key: 'forbiddenValues', path: '/parameterValues/0/value' },
    { key: 'regex', path: '/parameterValues/0/value' },
    { key: 'forbiddenRegex', path: '/parameterValues/0/value' },
  ],

  policyTableHeader: [
    { id: 1, label: "Select Type", position: "left" },
    { id: 2, label: "User/Group", position: "left" },
    { id: 3, label: "Query Actions", position: "left" },
    { id: 4, label: "Catalog", position: "left" },
    { id: 5, label: "Namespaces", position: "left" },
    { id: 6, label: "Tables", position: "left" },
    { id: 7, label: "Columns", position: "left" },
    { id: 8, label: "Masking", position: "left" },
    { id: 9, label: "Row Level Filter", position: "left" },
    { id: 10, label: "Tags Filter", position: "left" },
    { id: 11, label: "Actions", position: "left" },
  ],

  policyQueryData: [
    { value: "select", label: "Select" },
    { value: "insert", label: "Insert" },
    { value: "update", label: "Update" },
    { value: "delete", label: "Delete" },
    { value: "drop", label: "Drop" },
    { value: "join", label: "Join" },
  ],

  policyMaskingData: [
    { value: "partial_mask_last_4", label: "Partial Mask: show last 4" },
    { value: "partial_mask_first_4", label: "Partial Mask: show first 4" },
    { value: "hash", label: "Hash" },
    { value: "nullify", label: "Nullify" },
    { value: "date_year", label: "Date: show only year" },
  ],

  typeData: [
    { value: "user", label: "User" },
    { value: "group", label: "Group" },
  ],

  policyTabData: [
    { title: "Access", value: "access" },
    { title: "Masking", value: "masking" },
    { title: "Row Level Filter", value: "rowFilter" },
    { title: "Join", value: "join" },
  ],

  settingsTabData: [
    {
      title: "Alert Configurations",
      value: "alert_configurations",
    },
    { title: "Azure Configurations", value: "azure_configurations" },
    { title: "SMTP Configurations", value: "smtp_configurations" },
  ],

  settingsConfigTableHead: [
    { id: 1, label: "Type", position: "left" },
    { id: 2, label: "User/Group", position: "left" },
    { id: 3, label: "Catalogs", position: "left" },
    { id: 4, label: "NameSpace", position: "left" },
    { id: 5, label: "Tables", position: "left" },
    { id: 5, label: "Action", position: "left" },
  ],

  classificationHeader: [
    { id: 1, label: "Tag", position: "left" },
    { id: 2, label: "Display Name", position: "left" },
    { id: 3, label: "Description", position: "left" },
    { id: 4, label: "Actions", position: "left" },
  ],

  groupTableHeader: [
    { id: 1, label: "Group Name", position: "left" },
    { id: 2, label: "Description", position: "left" },
    { id: 3, label: "User", position: "left" },
    { id: 4, label: "Created At", position: "left" },
    { id: 5, label: "Actions", position: "left" },
  ],

  userTableHeader: [
    { id: 1, label: "UserName", position: "left" },
    { id: 2, label: "Email", position: "left" },
    { id: 2, label: "Status", position: "left" },
    { id: 3, label: "Block", position: "left" },
    { id: 4, label: "Created At", position: "left" },
    { id: 5, label: "Actions", position: "left" },
  ],

  groupUserTabData: [
    { title: "Users", value: "users" },
    { title: "Group", value: "group" },
  ],

  rowLevelFilterConditions: [
    { label: "Equals", value: "=" },
    { label: "Not Equals", value: "<>" },
    { label: "Greater Than", value: ">" },
    { label: "Less Than", value: "<" },
    { label: "Greater Than or Equal To", value: ">=" },
    { label: "Less Than or Equal To", value: "<=" },
    { label: "In", value: "IN" },
    { label: "Not In", value: "NOT IN" },
    { label: "Between", value: "BETWEEN" },
    { label: "Not Between", value: "NOT BETWEEN" },
    { label: "Like", value: "LIKE" },
    { label: "Not Like", value: "NOT LIKE" },
    { label: "Is Null", value: "IS NULL" },
    { label: "Is Not Null", value: "IS NOT NULL" },
    { label: "Contains", value: "CONTAINS" },
    { label: "Not Contains", value: "NOT CONTAINS" },
    // { label: "Is True", value: "IS TRUE" },
    // { label: "Is False", value: "IS FALSE" }
  ],  
};

export default constants;
