import React from "react";
import { useContext, useEffect, useState } from "react";
import HeaderV2 from "../../Widget/HeaderV2";
import ClassificationSidebar from "./ClassificationSidebar";
import ClassificationTable from "./ClassificationTable";
import { Context } from "../../Context";
import CustomCard from "../../Widget/CustomCard";
import NoData from "../../Widget/Nodata";
import { convertToTagsPatch, formatTexts, getLocalVariables } from "../../utils/utils";
import AddEditModal from "./AddEditModal";
import Tag_icon from "../../assets/tag.svg";
import disable_tag from "../../assets/disable-tag.svg";
import Button from "../../Widget/Button";
import CustomMenu from "../../Widget/CustomMenu";
import constants from "../../Constants";
import Deletepopup from "../../Components/Modals/Deletepopup";

const Classification = () => {
  const contextValues = useContext(Context);
  const [openComponentBar, setOpenComponentBar] = useState(true);
  const [openModal, setOpenModal] = useState({});
  const [titleData, setTitleData] = useState([]);
  const [mainData, setMaindata] = useState([]);
  const [tagsData, setTagsData] = useState([]);
  const [sideMenus, setSideMenus] = useState([]);
  const [selectedSourceIds, setSelectedSourceIds] = useState(["0"]);
  const [selectedFilterIds, setSelectedFilterIds] = useState(["0"]);
  const sidebarAction = JSON.parse(getLocalVariables("SIDEBAR_ACTION"));
  const [activeMenu, setActiveMenu] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(7);
  const [formData, setFormData] = useState({id:'', name: '', displayName: '', description: ''});
  const [openDeletePopup, setDeletepopup] = useState({});
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    const getApiData = async () => {
      const response = await contextValues?.Classifications({
        url: `${constants.url.Classifications}` + `?fields=termCount&limit=1000`,
        method: "GET",
        loader: true,
      });
      if ([200, 201].includes(response?.status) && response.data) {
        const result = response?.data?.data;
        setActiveMenu({ active: activeMenu?.active ? activeMenu?.active: result[0]?.name, url: window.location.pathname })
        setSideMenus(result?.map((val,index)=> { 
            return {id: index + 1,title: val?.name,url: `/classification`,menuName: val?.name,count: val?.termCount,disabled: val?.disabled}
        }))
        setMaindata(result);
      }};
    getApiData();
  }, [contextValues?.refresh]);

  useEffect(()=> {
    if(activeMenu?.active){
        setTitleData(mainData?.filter((val)=> val?.name === activeMenu?.active));
        const getTagsData = async () => {
            const response = await contextValues?.getTags({
              loader: true,method: "GET", url: `${constants.url.tags}?fields=usageCount&parent=${activeMenu?.active}&limit=1000`
            });
            if ([200, 201].includes(response.status) && response.data) {
                const result = response?.data?.data;
                setTagsData(result);
            }};
        getTagsData();
    }
  },[activeMenu])

    const handleCommonfieldChange = (event) =>{
      const { name, value } = event.target;
      let updatedFormdata = { ...formData,[name]: value };
      setFormData(updatedFormdata);
    }
    
    const handleSubmit = async()=>{
        if(openModal?.type === "classifications"){
            const response = await contextValues?.Classifications({
                url: `${constants.url.Classifications}`,
                method: "POST",
                loader: true,
                data: formData,
                toastMsg: "New classification added successfully"
              });
            if ([200, 201].includes(response?.status) && response?.data) {
                const result = response?.data?.data;
                setOpenModal({open: false});
                contextValues?.setRefresh(!contextValues?.refresh);
            }
        }else if(openModal?.type === "tags"){
            let payload = {...formData}
            payload["classification"] = activeMenu?.active;
            payload["style"] = {};
            const response = await contextValues?.getTags({
                url: `${constants.url.tags}`,
                method: "POST",
                loader: true,
                data: payload,
                toastMsg: "New tag added successfully"
              });
            if ([200, 201].includes(response?.status) && response?.data) {
                const result = response?.data?.data;
                setOpenModal({open: false});
                contextValues?.setRefresh(!contextValues?.refresh);
            }
        }else if(openModal?.type === "edit_tag"){
            const updatedFields = {};
            Object.keys(formData).forEach((key) => {
                if (formData[key] !== initialFormData[key]) {
                  updatedFields[key] = formData[key]; // Add only changed fields with new values
                }
              });
              // Check if there are any changes
              if (Object.keys(updatedFields).length > 0) {
                  const finalres = convertToTagsPatch(updatedFields); // Pass only the changed fields to update function
                  const response = await contextValues?.getTags({
                    url: `${constants.url.tags}/${formData?.id}`,
                    method: "PATCH",
                    loader: true,
                    toastMsg: "Tag updated successfully",
                    data: finalres ?? []
                  });
                if ([200, 201].includes(response?.status) && response.data) {
                    const result = response?.data?.data;
                    setOpenModal({open: false});
                    contextValues?.setRefresh(!contextValues?.refresh);
                }
            } else {
                console.log('No changes detected');
            }
        }
    }

    const handleEdit = (item) =>{
        const initialValues = {
            id: item?.id,
            name: item?.name || '',
            displayName: item?.displayName || '',
            description: item?.description || '',
          };
        setFormData(initialValues);
        setInitialFormData(initialValues);
        setOpenModal({open: true, title: "Edit Tag",type: "edit_tag"})
    }

    const handleDelete = async(item,key) =>{
        if(key === "classifications"){
            const response = await contextValues?.Classifications({
                url: `${constants.url.Classifications}/${item}?hardDelete=true&recursive=true`,
                method: "DELETE",
                loader: true,
                toastMsg: "Classification deleted successfully"
              });
            if ([200, 201].includes(response.status) && response.data) {
                const result = response?.data?.data;
                setActiveMenu({active: null})
                setDeletepopup({open: false});
                contextValues?.setRefresh(!contextValues?.refresh);
            }
        }else if(key === "tags"){
            const response = await contextValues?.getTags({
                url: `${constants.url.tags}/${item}?hardDelete=true&recursive=true`,
                method: "DELETE",
                loader: true,
                toastMsg: "Tag deleted successfully"
              });
            if ([200, 201].includes(response.status) && response.data) {
                const result = response?.data?.data;
                setDeletepopup({open: false});
                contextValues?.setRefresh(!contextValues?.refresh);
            }
        }
    }

    const handleEnableDisable = async(id,key) => {
        const response = await contextValues?.Classifications({
            url: `${constants.url.Classifications}/${id}`,
            method: "PATCH",
            loader: true,
            toastMsg: `Classification ${key} successfully`,
            data: [{"op":"replace","path": "/disabled","value": key =="enabled" ? false : true}]
            });
            if ([200, 201].includes(response?.status) && response?.data) {
                const result = response?.data?.data;
                contextValues?.setRefresh(!contextValues?.refresh);
            }
    }

  return (
    <>
      <div className="w-full h-screen overflow-auto bg-backgroundColor">
        <HeaderV2 title="Classifications" className="ml-10" />
        <div className="flex w-full ">
          <ClassificationSidebar
            className="h-full rounded z-99"
            open={openComponentBar}
            setPage={setPage}
            sideMenus={sideMenus}
            setOpen={setOpenComponentBar}
            openModal={openModal}
            setOpenModal={setOpenModal}
            activeMenu={activeMenu}
            setFormData={setFormData}
            setActiveMenu={setActiveMenu}
            selectedFilterIds={selectedFilterIds}
            setSelectedFilterIds={setSelectedFilterIds}
            selectedSourceIds={selectedSourceIds}
            setSelectedSourceIds={setSelectedSourceIds}
          />
          <div
            className={`${
              sidebarAction && openComponentBar
                ? "w-[calc(100vw-34.6rem)] scale-out"
                : sidebarAction && !openComponentBar
                  ? "w-[calc(100vw-17.6rem)] scale-out"
                  : !sidebarAction && openComponentBar
                    ? "w-[calc(100vw-22.8rem)] scale-out"
                    : "w-[calc(100vw-5.8rem)] scale-out"
            } pr-10`}
          >
            <CustomCard className="border-0 max-h-[calc(100vh-5rem)] overflow-y-auto">
            <div className="flex justify-between">
                <div className="flex items-center pl-10 py-5">
                <img src={Tag_icon} alt="" className="w-20 h-20 mr-4" />
                <h2 className="font-bold text-md mr-3">{titleData[0]?.name}</h2>
                {titleData[0]?.provider === "system" && (
                    <span className="bg-[#06A4A4] rounded-md font-bold px-6 text-[11px] text-white mr-3">
                    <i className="fa-solid fa-lock mr-2" role="presentation" />
                    {titleData[0]?.provider}
                    </span>
                )}
                {titleData[0]?.mutuallyExclusive && (
                    <span className="bg-inputOrange rounded-md font-bold px-6 text-[11px] text-white">
                    {"Mutually Exclusive"}
                    </span>
                )}
                </div>
                <div className="flex items-center mr-[30px] justify-center">
                    <Button
                        variant="outline"
                        disable={titleData[0]?.disabled}
                        startIcon={<i className="fa-solid fa-plus mr-6" />}
                        onClick={() => {
                        setFormData(null);
                        setOpenModal({
                            open: true,
                            title: `Adding new tag on ${activeMenu?.active}`,
                            type: "tags" 
                        });
                        }}
                        className={`w-11/12 mr-5  mt-[10px]`}
                    >
                        Add Tag
                    </Button>
                    <CustomMenu
                        className="mt-[10px]"
                        placement="bottomRight"
                        items={[
                        titleData[0]?.provider !== "system" && {
                            key: "1",
                            label: (
                            <div
                                role="presentation"
                                className="flex items-center"
                                onClick={() => {
                                setDeletepopup({
                                    open: true,
                                    id: titleData[0]?.id,
                                });
                                }}
                            >
                                <i className="fa-solid fa-trash-can w-16" />
                                <div className="flex flex-col">
                                <span>Delete</span>
                                <span className="text-[12px] text-[#757575]">
                                    Deleting this classification will permanently
                                    <br /> remove its metadata from guardiuum.
                                </span>
                                </div>
                            </div>
                            ),
                        },
                        titleData[0]?.provider === "system" && !titleData[0]?.disabled && {
                            key: "2",
                            label: (
                            <div
                                role="presentation"
                                className={`flex items-center ${activeMenu?.active == "Tier" ? "opacity-50 text-xs text-gray-600 cursor-not-allowed" : ""}`}
                                onClick={() => handleEnableDisable(titleData[0]?.id,"disabled")}
                            >
                                <img src={disable_tag} className="mr-4 w-12 h-12 " />
                                <div className="flex flex-col">
                                <span>Disable</span>
                                <span className="text-[12px] text-[#757575]">
                                    By disabling classification, you will not be able
                                    <br />
                                    to search by, or assign associated tags to any entity.
                                </span>
                                </div>
                            </div>
                            ),
                        },
                        titleData[0]?.provider === "system" && activeMenu?.active !== "Tier" && titleData[0]?.disabled && {
                            key: "3",
                            label: (
                            <div
                                role="presentation"
                                className="flex items-center"
                                onClick={() => handleEnableDisable(titleData[0]?.id, "enabled")}
                            >
                                <img src={disable_tag} className="mr-4 w-12 h-12 " />
                                <div className="flex flex-col">
                                <span>Enable</span>
                                <span className="text-[12px] text-[#757575]">
                                    By enabling classification, you will be able to
                                    <br />
                                    search by, or assign associated tags to any entity.
                                </span>
                                </div>
                            </div>
                            ),
                        },
                        ]}
                    />
                </div>
            </div>

            <div className="flex flex-col items-start pl-38">
                <h2 className="text-grey">Description</h2>
                {titleData[0]?.description && formatTexts(titleData[0]?.description,null)}
            </div>

            <div className={`px-10 ${titleData[0]?.disabled ? "opacity-50" : "opacity-100"}`}>
                {tagsData ? (
                    <ClassificationTable
                      data={tagsData}
                      page={page}
                      setPage={setPage}
                      activeMenu={activeMenu}
                      rowsPerPage={rowsPerPage}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                    />
                ) : (
                    <NoData />
                )}
            </div>
            </CustomCard>
            <AddEditModal
               openModal={openModal}
               setOpenModal={setOpenModal}
               formData={formData}
               handleSubmit={handleSubmit}
               handleCommonfieldChange={handleCommonfieldChange}
            />
          </div>
          <Deletepopup
            open={openDeletePopup?.open}
            setOpen={setDeletepopup}
            onDelete={() => handleDelete(openDeletePopup?.id, "classifications")
            }
          />
        </div>
      </div>
    </>
  );
};

export default Classification;
