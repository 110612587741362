import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "./Context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import Route from "./Route";
import Loading from "./Widget/Loading";
import TestconnectionModal from "./Components/Modals/TestconnectionModal";
import Body from "./Body";

const App = () => {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID ?? "Client Id is required."}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#007eff",
            },
            components: {
              Button: {
                primaryColor: "#007eff",
                defaultBg: "#ffffff",
                defaultHoverColor: "#007eff",
                defaultHoverBorderColor: "#007eff",
                defaultActiveBorderColor: "#007eff",
                defaultActiveColor: "#007eff !important",
              },
              Select: {
                zIndexPopup: 9999,
              },
              Tooltip: {
                zIndexPopup: 9999,
              },
            },
          }}
        >
          <Provider>
            <Body>
              <Loading />
              <Route />
              <TestconnectionModal />
            </Body>
          </Provider>
        </ConfigProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};

export default App;
